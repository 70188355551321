.charmonman-regular {
  font-family: "Charmonman", cursive;
  font-weight: 400;
  font-style: normal;
}
.montecarlo-regular {
  font-family: "MonteCarlo", cursive;
  font-weight: 400;
  font-style: normal;
}

.App {
  text-align: center;

  font-family: "MonteCarlo", cursive;
  font-weight: 400;
  font-style: normal;
}

.pageTitul {
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  font-family: "Charmonman";
}
.pageImg {
  width: 400px;
  height: 350px;
}
.pages {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
}
h2 {
  font-family: "MonteCarlo";
  font-size: 25px;
  color: white;
}
